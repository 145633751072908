export default {
	state: {
		// header 
		searchValue: '',
    areaCut: 0,
		shouPathValue:'',
		classifyListValue:[],
		homeIsGoodsValue:null,
		GoodsDetailsIsGoodsValue:null,
	},
	getters: {
    getareaCut: state => {
			return state.areaCut
    },
		getSearchValue: state => {
			console.log(state.searchValue)
			return state.searchValue
		},
		getshouPathValue:state => {
			console.log(state.shouPathValue)
			return state.shouPathValue
		},
		getclassifyListValue:state => {
			console.log(state.classifyListValue)
			return state.classifyListValue
		},
		gethomeIsGoodsValue:state => {
			console.log(state.homeIsGoodsValue)
			return state.homeIsGoodsValue
		},
		getGoodsDetailsIsGoodsValue:state => {
			console.log(state.GoodsDetailsIsGoodsValue)
			return state.GoodsDetailsIsGoodsValue
		},
	},
	mutations: {
    changeAreaCut(state, areaCut) {
      state.areaCut = areaCut;
    },
		changeSearchData(state, searchValue) {
			state.searchValue = searchValue;
		},
		changeshouPathValue(state,shouPathValue) {
			state.shouPathValue = shouPathValue;
		},
		mapchangeclassifyListData(state, searchValue) {
			console.log(searchValue)
			state.classifyListValue = searchValue;
		},
		changehomeIsGoodsData(state, homeIsGoodsValue) {
			console.log(homeIsGoodsValue)
			state.homeIsGoodsValue = homeIsGoodsValue;
		},
		changeGoodsDetailsIsGoodsData(state, GoodsDetailsIsGoodsValue) {
			console.log(GoodsDetailsIsGoodsValue)
			state.GoodsDetailsIsGoodsValue = GoodsDetailsIsGoodsValue;
		},
	},
	actions: {
    changeAreaCut({
			commit
		}, value) {
			commit('changeAreaCut', value);
		},
		changeSearchData({
			commit
		}, value) {
			commit('changeSearchData', value);
		},
		changeshouPathValue({
			commit
		}, value) {
			commit('changeshouPathValue', value);
		},
		changeclassifyListData({
			commit
		}, value) {
			commit('mapchangeclassifyListData', value);
		},
		changehomeIsGoodsData({
			commit
		}, value) {
			commit('changehomeIsGoodsData', value);
		},
		changeGoodsDetailsIsGoodsData({
			commit
		}, value) {
			commit('changeGoodsDetailsIsGoodsData', value);
		},
	}
}
