<template>
	<div :class="themeClass">
		<div class="topbar ">
			<div class="nav">
				<ul style="float: left;">
					<!-- 已登录跳转首页 -->
					<li style="margin-left: 0;">
						<span class="font-color-333 pointer">欢迎来到聚材通平台!</span>
					</li>
					<!-- 未登录跳转登录页面 -->

					<li style="margin-left: 18px;" v-if="getUserInfo == null">
						<div @click="login" class="font-color-333 pointer">你好，请登录</div>
					</li>
					<li style="margin-left: 18px;" v-if="getUserInfo != null">
						<router-link to="/PersonalContent">你好，{{getUserInfo.fUserName}}</router-link>

					</li>
					<li style="margin-left: 18px;" v-if="getUserInfo != null&&getUserInfo.fVerifiedStatus == false">
						<view @click="jumpPage">去认证</view>

					</li>
					<li class="color-theme text-underline" style="margin-left: 18px;" v-if="getUserInfo != null"
						@click="logout">
						<router-link to="/login">退出登录</router-link>

					</li>
					<li>
						<router-link to="/register" v-if="getUserInfo == null">注册</router-link>
					</li>
				</ul>
				<ul>

					<li>
						<div class="pointer font-color-333" @click="toOpenShop">
							我要开店
						</div>
						<!-- <router-link to="/OpenShop">我要开店</router-link> -->
					</li>
					<li @click="getCarCuont">
						<!-- <el-badge :value="getCarCount" class="item"> -->
						<!-- <el-button size="small">评论</el-button> -->
						<!-- <router-link to="/shoppingCart">购物车<span class="raspan"
								v-if="getCarCount!=0">{{getCarCount}}</span></router-link> -->
						<!-- </el-badge> -->

					</li>

					<li>
						<router-link to="/PersonalContent/MyCollect">我的收藏</router-link>
					</li>
					<!-- <li>
						<router-link to="/PersonalContent">个人中心</router-link>
					</li> -->
					<li>
						<router-link to="/News">消息中心<span :class="getCount > 99 ? 'raspan1' : 'raspan'"
								v-if="getCount!=0">{{ getCount > 99 ? '99+' : getCount }}</span></router-link>
					</li>
					<li>
						<router-link to="/HelpCenter/HelpCenter">帮助中心</router-link>
					</li>
					<li class="position-relative" @mouseenter="teleJCShow=true" @mouseleave="teleJCShow=false">
						<span class="font-color-333">手机聚材通</span>
						<div v-if="teleJCShow" class="teleJC">
							<div class="teleJC-item flex-row">
								<div class="piccode flex-row-center-center">
									<!-- <div class="ju radius5 bg-theme font-color-fff font-size13 text-center font-weight700"></div> -->
								</div>
								<div class="teleJC-item-right margin-l-10">
									<div class="font-color-ff6666 font-size13">下载聚材通手机App</div>
									<div class="color-theme font-size12">请前往各手机应用市</div>
									<div class="color-theme font-size12">场内搜索“聚材通”</div>
									<div class="color-theme font-size12">下载</div>
									<!-- <div class="font-color-666 font-size12">适用:安卓、iOS、iPad</div> -->
								</div>
							</div>
							<div class="teleJC-item flex-row">
								<div class="pic flex-row-center-center">
									<!-- <div class="ju radius5 bg-theme font-color-fff font-size13 text-center font-weight700"></div> -->
								</div>
								<div class="teleJC-item-right margin-l-10">
									<div class="font-color-000 font-size13">关注聚材通微信</div>
									<div class="color-theme font-size12">微信扫一扫关注聚材通服</div>
									<div class="color-theme font-size12">务号,更多优惠福利</div>
								</div>
							</div>
							<!-- <div class="teleJC-item flex-row"> -->
							<!-- <div class="pic flex-row-center-center"> -->
							<!-- <div class="ju radius5 bg-theme font-color-fff font-size13 text-center font-weight700"></div> -->
							<!-- </div> -->
							<!-- <div class="teleJC-item-right margin-l-10"> -->
							<!-- <div class="font-color-000 font-size13">聚材通商家</div> -->
							<!-- <div class="color-theme font-size12">平台商家管理更健康</div> -->
							<!-- <div class="font-color-666 font-size12">适用:安卓、iOS、iPad</div> -->
							<!-- </div> -->
							<!-- </div> -->
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import {
	mapActions,
	mapGetters
} from "vuex";
export default {
	name: 'Top',
	data() {
		return {
			teleJCShow: false, //是否显示手机聚材通二维码
			register: false, // 是否显示注册组件
			userinfo: {},
			name: '',
		}
	},
	computed: {
		// username() {
		// 	const username =this.$store.getters.getUser.fUserName
		// 	return username || this.name
		// }
		...mapGetters(['getUserInfo', 'getThemeName', 'getCarCount', 'getCount']),
		themeClass() {
			return `theme-${this.getThemeName}`;
		}
	},
	mounted() {
		this.getCounts()
		// this.getUserInfo()
		console.log(this.$store);
	},
	methods: {
		jumpPage() {
			this.ApiRequestPostNOMess('api/mall/ebcustomer/adjust-bill/get-current-bill')
			.then(
				res => {
					console.log('用户完善资料', res);
					if (res.obj) {
						if (res.obj.fApproveStatus == 1) { //1审核中 254审核成功
							this.$router.push({
								path: "/UnderReview",
							});
						} else {
							this.$router.push("/PersonalContent/Allperfect")
						}
						this.closeMo();
					} else {
						this.$router.push("/PersonalContent/Allperfect")
						this.closeMo();
					}
				},
				error => {
					this.$router.push("/PersonalContent/Allperfect")
					this.closeMo();
				}
			);
		},
		getCarCuont() {
			this.ApiRequestPostNOMess(
				"api/mall/ebsale/shopping-cart/get-shopping-cart-count"
			).then(
				(result) => {
					this.$store.commit("setCarCount", result.obj.number);
				},
				(rej) => {}
			);
		},
		...mapActions(["setUser"]),
		login() {
			// this.$store.commit("setShowLogin", true);
			this.$router.push('/login')
		},
		getCounts() {
			this.ApiRequestPost('api/mall/ebbase/announcement-notice-record/get-curret-user-record-count', {

			}).then(
				result => {
					this.$store.commit("setCount", result.obj.fCount);
				},
				error => {

				}
			)
		},
		toOpenShop() {
			if (this.getUserInfo != null) {
				if (this.getUserInfo.fCommerceTypeID != '2') {
					this.$message('个人用户不能开店');
					return;
				} else {
					this.$router.push('/OpenShop')
				}
			} else {
				this.$router.push('/OpenShop')
			}
		},
		// 退出登录
		logout() {
			this.visible = false;
			// 清空本地登录信息
			// localStorage.setItem("user", "");
			localStorage.clear();
			sessionStorage.clear();
			// 清空vuex登录信息
			this.$store.commit("setUser", null);
			this.$store.commit("setCarCount", 0);
			this.$store.commit("setUserInfo", null);
			this.$store.commit("setMenBList", null);
			this.$store.commit("setMenPList", null);
			this.$store.commit("setCount", null);
			// console.log(this.username,"username");
			// this.$router.push('./login')
			// this.notifySucceed("成功退出登录");
		}
	}
}
</script>

<style lang="scss" scoped>
.raspan {
	margin-left: 3px;
	background-color: #FF0000;
	border-radius: 50%;
	color: #FFF;
	display: inline-block;
	font-size: 12px;
	height: 16px;
	width: 16px;
	line-height: 16px;

	text-align: center;
	white-space: nowrap;

}

.raspan1 {
	margin-left: 3px;
	background-color: #FF0000;
	border-radius: 50%;
	color: #FFF;
	display: inline-block;
	font-size: 12px;
	height: 23px;
	width: 23px;
	line-height: 23px;

	text-align: center;
	white-space: nowrap;
}

/* 手机聚材通二维码 */
.position-relative {
	position: relative;
}

.teleJC {
	position: absolute;
	right: 3px;
	top: 40px;
	width: 300px;
	// height: 248px;
	border: 1px solid #e4e4e4;
	background-color: #F8F9FE;
}

.teleJC-item {
	margin: 10px;
	margin-right: 0;
}

.pic {
	width: 95px;
	height: 95px;
	background: url('../../assets/u42.jpg');
	background-size: 100%;
}

.piccode {
	width: 95px;
	height: 95px;
	background: url('../../assets/jcadmin_img.png') no-repeat;
	background-size: 100%;
}



.ju {
	width: 22px;
	height: 22px;
	line-height: 22px;
}

.teleJC-item-right div {
	line-height: 17px;
	text-align: left;
}

.teleJC-item-right div:nth-child(1) {
	margin-bottom: 10px;
}

.teleJC-item-right div:nth-child(2) {
	margin-bottom: 3px;
}

.teleJC:before {
	content: "";
	border-right: 10px solid transparent;
	border-left: 10px solid transparent;
	border-bottom: 10px solid #e4e4e4;
	width: 0px;
	height: 0px;
	position: absolute;
	left: 265px;
	top: -11px;
}

.teleJC:after {
	content: "";
	border-right: 10px solid transparent;
	border-left: 10px solid transparent;
	border-bottom: 10px solid #F8F9FE;
	width: 0px;
	height: 0px;
	position: absolute;
	left: 265px;
	top: -10px;
}

/* 顶部导航栏CSS */
.color-theme {
	@include themify($themes) {
		color: themed('themes_color');
	}
}

.bg-theme {
	@include themify($themes) {
		background-color: themed('themes_color');
	}
}

ul li {
	list-style: none;
}

a {
	text-decoration: none !important;
}

.topbar {
	height: 30px;
	background-color: rgba(242, 242, 242, 1);
	top: 0;
	z-index: 10000;
	position: fixed;
	width: 100%;

}

.aaa:hover {
	border-bottom: 1px solid;
}

.topbar .nav {
	max-width: 1100px;
	min-width: 1100px;
	margin: 0 auto;
}

.topbar .nav ul {
	float: right;
	margin: 0px 0px !important;
}

.topbar .nav li {
	float: left;
	height: 30px;
	color: #fff;
	font-size: 14px !important;
	text-align: center;
	line-height: 30px;
	margin-left: 10px;
}

.topbar .nav .sep {
	color: #fff;
	font-size: 12px;
	margin: 0 5px;
}

.topbar .nav li .el-button {
	color: #333;
	font-size: 12px;
}

.topbar .nav .el-button:hover {
	color: #333;
}

.topbar .nav li a {
	color: #333;
}

.topbar .nav a:hover {
	color: #333;
}

.topbar .nav .shopCart {
	width: 120px;

}

.topbar .nav .shopCart:hover {
	background: #fff;
}

.topbar .nav .shopCart:hover a {
	color: #ff6700;
}

.topbar .nav .shopCart-full {
	width: 120px;
	background: #ff6700;
}

.topbar .nav .shopCart-full a {
	color: white;
}
</style>
