/*
 * @Description: Vuex入口
 * @Author: hai-27
 * @Date: 2020-02-07 16:23:00
 * @LastEditors: hai-27
 * @LastEditTime: 2020-02-25 22:51:50
 */
import   createPersistedState from 'vuex-persistedstate'
import Vue from 'vue'
import Vuex from 'vuex'

import publicStore from './modules/public'
import user from './modules/user'
import shoppingCart from './modules/shoppingCart'
import paymentStore from './modules/payment'
import globalSearch from "./modules/globalSearch"


Vue.use(Vuex)

export default new Vuex.Store({
  strict: true,
  modules: {
		publicStore,
    user,
    shoppingCart,
	paymentStore,
	globalSearch
	
  },
   plugins:[createPersistedState(
   {
	   filter:((f)=> {
		   console.log(f)
		  return f.type!='changeSearchData'
	   })
   }
   )]
})
