import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Directives from './directives';
import SERVER from '../config/config.json'

import {
	MessageBox,
	Message,
	Notification
} from 'element-ui'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './utils/directives.js'
import './utils/imgCom.js'
Vue.use(ElementUI);
// 全局函数及变量
import Global from './Global';
Vue.use(Global);
import './style/iconfont/iconfont.css'
import './icons' // svg 
import {
	getTime,
	toTree,
	getDate,
	getYM,
	getMD
} from './utils/public';
import './permission.js'
import {
	getUploadFileURL
} from './utils/uploadFile.js'
// import './permission' // permission control
import axios from 'axios';
Vue.prototype.$axios = axios;

import {
	getUserNews
} from './utils/pushNewUserInfo.js';
Vue.prototype.getUserNews = getUserNews;


import {
	VueJsonp
} from 'vue-jsonp'
import './utils/menuCommand.js';
Vue.use(Directives);

Vue.use(VueJsonp)

// 存入缓存 应用类型
window.sessionStorage.setItem("fAppTypeID", 2);
// 全局请求拦截器
// 环境的切换
// if (process.env.NODE_ENV === 'development') {
//   axios.defaults.baseURL = 'http://192.168.201.34:51045/'
// } else if (process.env.NODE_ENV === 'production') {
//   axios.defaults.baseURL = 'https://www.henghesoft.com:8444/'
// }
axios.defaults.baseURL = SERVER.Environment == 'test' ? SERVER.API_SERVER : window.API_SERVER;
// axios.defaults.baseURL = 'http://www.henghesoft.com:8444/'
// axios.defaults.baseURL = 'https://www.xjjcjc.com:7443/'
// 请求拦截器
axios.interceptors.request.use(
	config => {
		// 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
		// 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断

		const token = sessionStorage.getItem("token")
		config.headers.ApplicationType = 2
		config.headers.Type = 'Client'
		token && (config.headers.Authorization = 'Bearer ' + token)
		return config
	},
	error => {
		return Promise.error(error)
	})

// 响应拦截器
axios.interceptors.response.use(
	response => {

		if (response.status === 200 || response.status === 204) {
			return Promise.resolve(response)
		} else {
			return Promise.reject(response)
		}
	},
	// 服务器状态码不是200的情况
	error => {
		if (error.response.status) {
			switch (error.response.status) {
				// 401: 未登录
				// 未登录则跳转登录页面，并携带当前页面的路径
				// 在登录成功后返回当前页面，这一步需要在登录页操作。
				case 401:
					localStorage.removeItem("token")
					sessionStorage.clear();
					// 清空vuex登录信息
					store.commit("setUser", null);
					store.commit("setCarCount", 0);
					store.commit("setUserInfo", null);
					router.currentRoute.path !== 'login' &&
						router.replace({
							path: '/login',
							query: {
								redirect: router.currentRoute.path,
								type: 1
							},
						})
					break
					// 403 token过期
					// 登录过期对用户进行提示
					// 清除本地token和清空vuex中token对象
					// 跳转登录页面
				case 403:

					if (router.currentRoute.path !== '/login' && router.currentRoute.path !== '/luckMain' && router.currentRoute.path !== '/luckMainPlatform' && router.currentRoute.path !== '/paymentOrder') {
						Message({
							message: error.response.data.error.message,
							type: 'warning',
							duration: 5000,
							showClose: true
						});
					}
					break
					// 404请求不存在
				case 404:
					Notification.error({
						title: '提示',
						message: '网络请求不存在',
						duration: 0,
						customClass: 'notificClass'
					});

					break
				case 500:
					Notification.error({
						title: '提示',
						message: '系统内部错误！',
						duration: 0,
						customClass: 'notificClass'
					});

					break
					// 其他错误，直接抛出错误提示
				default:
					Notification.error({
						title: '提示',
						message: error,
						duration: 0
					});

			}
			return Promise.reject(error.response)
		}
	}
)
async function ApiRequestPost(url, data) {
	return new Promise((resolve, reject) => {
		axios.post(url, data)
			.then(res => {
				resolve(res.data)

				if (res.data.code == 'HengHeCode1000') {

				} else if (res.data.code == 'HengHeCode0999') {
					if (res.data.message != '' && res.data.message != undefined) {
						Message({
							message: res.data.message
						});
					}

				} else {
					if (res.data.message != '' && res.data.message != undefined) {

						Message({
							message: res.data.message,
							type: 'success'
						});
					}

				}
			})
			.catch(err => {
				reject(err.data)
			})
	})
}

async function ApiRequestPostNOMess(url, data) {
	return new Promise((resolve, reject) => {
		axios.post(url, data)
			.then(res => {
				resolve(res.data)
			})
			.catch(err => {
				reject(err.data)

			})
	})
}
async function ApiRequestGet(url) {
	return new Promise((resolve, reject) => {
		axios.get(url)
			.then(res => {
				resolve(res.data)
			})
			.catch(err => {
				reject(err.data)

			})
	})
}

async function ApiRequestPut(url, data) {
	return new Promise((resolve, reject) => {
		axios.put(url, data)
			.then(res => {
				resolve(res.data)

				if (res.data.code == 'HengHeCode1000') {
					console.log(11)
				} else if (res.data.code == 'HengHeCode0999') {
					if (res.data.message != '' && res.data.message != undefined) {
						Message({
							message: res.data.message
						});
					}

				} else {
					if (res.data.message != '' && res.data.message != undefined) {
						Message({
							message: res.data.message,
							type: 'success'
						});
					}

				}
			})
			.catch(err => {
				reject(err.data)

			})
	})
}
async function ApiRequestPutNOMess(url, data) {
	return new Promise((resolve, reject) => {
		axios.put(url, data)
			.then(res => {
				resolve(res.data)
			})
			.catch(err => {
				reject(err.data)
			})
	})
}
Vue.prototype.fAppTypeID = "2"
Vue.prototype.ApiRequestGet = ApiRequestGet
Vue.prototype.ApiRequestPost = ApiRequestPost
Vue.prototype.ApiRequestPut = ApiRequestPut
Vue.prototype.ApiRequestPostNOMess = ApiRequestPostNOMess
Vue.prototype.ApiRequestPutNOMess = ApiRequestPutNOMess
Vue.prototype.getUploadFileURL = getUploadFileURL; //拼接图片展示的绝对路径
Vue.prototype.weblogUrl = require('@/assets/weblogo.png');

Vue.prototype.rowStatus_noChange = 2;
// 新增
Vue.prototype.rowStatus_newAdd = 4;
// 删除
Vue.prototype.rowStatus_delete = 8;
// 编辑
Vue.prototype.rowStatus_change = 16;


// 全局拦截器,在进入需要用户权限的页面前校验是否已经登录
router.beforeResolve((to, from, next) => {
	const loginUser = store.state.user.user;
	// 判断路由是否设置相应校验用户权限
	if (to.meta.requireAuth) {
		if (!loginUser) {
			// 没有登录，显示登录组件
			store.dispatch("setShowLogin", true);
			if (from.name == null) {
				//此时，是在页面没有加载，直接在地址栏输入链接，进入需要登录验证的页面
				next("/");
				return;
			}
			// 终止导航
			next(false);
			return;
		}
	}
	next();
});

// vue中切换页面时让 页面的滚动条自动滚动到顶部的方法
router.afterEach((to, from, next) => {
	window.scrollTo(0, 0);
	// 或
	// window.scroll(0, 0);
});

// 相对时间过滤器,把时间戳转换成时间
// 格式: 2020-02-25 21:43:23
Vue.filter('dateFormat', (dataStr) => {
	var time = new Date(dataStr);

	function timeAdd0(str) {
		if (str < 10) {
			str = '0' + str;
		}
		return str;
	}
	var y = time.getFullYear();
	var m = time.getMonth() + 1;
	var d = time.getDate();
	var h = time.getHours();
	var mm = time.getMinutes();
	var s = time.getSeconds();
	return y + '-' + timeAdd0(m) + '-' + timeAdd0(d) + ' ' + timeAdd0(h) + ':' + timeAdd0(mm) + ':' + timeAdd0(s);
});
// 相对时间过滤器,把时间戳转换成时间
// 格式: 2020-02-25
Vue.filter('formatDate', (dataStr) => {
	var time = new Date(dataStr);

	function timeAdd0(str) {
		if (str < 10) {
			str = '0' + str;
		}
		return str;
	}
	var y = time.getFullYear();
	var m = time.getMonth() + 1;
	var d = time.getDate();
	return y + '-' + timeAdd0(m) + '-' + timeAdd0(d);
});

//钱数格式化例如1000格式化为1,000.00
Vue.filter('NumFormat', function(value) {
	if (!value) return '0.00';
	value = Number(value).toFixed(2).toString();
	var intPart = parseInt(Number(value)); //获取整数部分
	var intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,'); //将整数部分逢三一断
	var floatPart = ".00"; //预定义小数部分
	var value2Array = value.split(".");
	//=2表示数据有小数位
	if (value2Array.length == 2) {
		floatPart = value2Array[1].toString(); //拿到小数部分
		if (floatPart.length == 1) { //补0,实际上用不着
			return intPartFormat + "." + floatPart + '0';
		} else {
			return intPartFormat + "." + floatPart;
		}
	} else {
		return intPartFormat + floatPart;
	}
});

//添加水印  用法v-watermark="{text:'新疆聚财电子商务有限公司',font:'18px Alibaba_PuHuiTi_Regular',width:'100%',textColor:'#999999'}"
Vue.directive('watermark', (el, binding) => {
  function addWaterMarker(str, parentNode, font, textColor) {
    var can = document.createElement('canvas')
    parentNode.appendChild(can)
    can.width = 600
    can.height = 300
    can.style.display = 'none'
    var cans = can.getContext('2d')
    cans.rotate(-20 * Math.PI / 180)
    cans.font = font || '16px Microsoft JhengHei'
    cans.fillStyle = textColor || 'rgba(180, 180, 180, 0.3)'
    cans.textAlign = 'center'
    cans.textBaseline = 'Middle'
    cans.fillText(str, can.width / 3, can.height / 2)
    parentNode.style.backgroundImage = 'url(' + can.toDataURL('image/png') + ')'
  }
  addWaterMarker(binding.value.text, el, binding.value.font, binding.value.textColor)
})

//全局组件
import MyMenu from './components/MyMenu';
Vue.component(MyMenu.name, MyMenu);
import MyList from './components/MyList';
Vue.component(MyList.name, MyList);
import MyLogin from './components/MyLogin';
Vue.component(MyLogin.name, MyLogin);
import MyRegister from './components/MyRegister';
Vue.component(MyRegister.name, MyRegister);
import Footer from './components/loyout/footer';
Vue.component(Footer.name, Footer);
import Top from './components/loyout/top';
Vue.component(Top.name, Top);
import Header from './components/loyout/header';
Vue.component(Header.name, Header);
import StoreLogin from './components/login/storeLogin';
Vue.component(StoreLogin.name, StoreLogin);
import Attestation from './components/login/attestation';
Vue.component(Attestation.name, Attestation);
import Suspension from './components/loyout/suspension';
Vue.component(Suspension.name, Suspension);
Vue.config.productionTip = false;

Vue.prototype.getTime = getTime
Vue.prototype.toTree = toTree
Vue.prototype.getDate = getDate
Vue.prototype.getYM = getYM;
Vue.prototype.getMD = getMD;
Vue.prototype.$bus = new Vue();
new Vue({
	el: '#app',
	router,
	store,
	render: h => h(App)
})
