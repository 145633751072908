<template>
	<div :class="themeClass" class="pt20">
		<!-- <el-menu default-active="2" class="el-menu-vertical-demo menu-width" @open="handleOpen" @close="handleClose">
			<el-submenu :index="index + '' " v-for="(i,index) of menuItem" :key="index">
				<template slot="title">
					<i class="el-icon-s-order"></i>
					<span>{{i.title}}</span>
				</template>
				<ul class="menuchild">
					<li v-for="(j,indexs) of i.pItem" :key="indexs" @click="getUrl(j)">
						<a style="font-size:10px;"></a>{{j.name}}
					</li>
				</ul>
			</el-submenu>
		</el-menu> -->

		<div class="padding-13 border  " v-for="(item,index) of items" :key="index" >
			<span class="titlefont"  >{{item.FApplicationText}}</span>
			<div v-for="(i,indes) of item.children" :key="indes" class="pading-l-30" @click="getUrl(i)" >
				<div v-if="i.FApplicationID == 212001010001">
					<el-badge :max="99" :value="num1" :hidden="num1 == 0?true:false" class="myitem">
						<span @click="toRouter(i.FApplicationName)" :class="selectId==i.FApplicationID?'color-theme font-weight700':''">{{i.FApplicationText}}</span>
					</el-badge>
				</div>
				<div v-else-if="i.FApplicationID == 212001010006">
					<el-badge :max="99" :value="num2" :hidden="num2 == 0?true:false" class="myitem">
						<span @click="toRouter(i.FApplicationName)" :class="selectId==i.FApplicationID?'color-theme font-weight700':''">{{i.FApplicationText}}</span>
					</el-badge>
				</div>
				<div v-else-if="i.FApplicationID == 212001020006">
					<el-badge :max="99" :value="num3" :hidden="num3 == 0?true:false" class="myitem">
						<span @click="toRouter(i.FApplicationName)" :class="selectId==i.FApplicationID?'color-theme font-weight700':''">{{i.FApplicationText}}</span>
					</el-badge>
				</div>
				<div v-else>
					<span @click="toRouter(i.FApplicationName)" :class="selectId==i.FApplicationID?'color-theme font-weight700':''">{{i.FApplicationText}}</span>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	import parmsd from '@/views/business/params'
	export default {
		name: 'LeftMenus',
		data() {
			return {
				items: [],
				selectId: '212001010007',
				num1: 0,
				num2: 0,
				num3: 0,
			}
		},
		computed: {
			...mapGetters(['getThemeName','getUserInfo']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
			activeMenu() {
				// console.log("this.$route.path",this.$route.path);
				return this.$route.path;
			}
		},
		watch: {
		    // 监听路由对象中的变化
		    '$route': {
		      handler: function (to, from) {
		        // 路由发生变化时的处理逻辑
		        console.log('Route changed from', from.path, 'to', to.path);
				if (to.path == '/businessme/AllOrder') {
					this.selectId = '212001010007'
				}
		      },
		      // 设置为深度监听
		      deep: true
		    }
		},
		created() {
			console.log(123456,)
			this.getMenList();
		},
		methods: {
			toRouter(url) {
        console.log(url)
        this.$router.push(url)

      },
			handleOpen(key, keyPath) {

			},
			handleClose(key, keyPath) {

			},
			getUrl(e) {
				if (e.path == "") {
					this.tipsInfo("暂无此页面")
					return;
				}
				this.selectId = e.FApplicationID;
				this.$router.push({
					path: e.FApplicationName,
					query: {
						type: e.type
					}
				});
			},
			getMenList(){
				this.ApiRequestPostNOMess('api/mall/sys/application/get-user-app-all-child-by-app-name',{
					  "fApplicationName": "我的业务",
					  "appTypeId": this.fAppTypeID,
					  "fSubsystemID":120
				}).then(
					res=>{

						if(JSON.parse(res.obj).length>0){
							let menArr = [];
							menArr = this.toMenTree(JSON.parse(res.obj));
							let menArr2  = [];
							menArr2 = menArr[0].children;
							this.items = menArr2;
							// 订单和派车单都有已过期 重订单详情中返回会选中派车单已过期  所以在这做区分
							if (sessionStorage.getItem('itemPath') == 'OrderDetails' &&  sessionStorage.ApplicationName == '已过期') { // 订单 过期
								this.selectId = this.items[0].children[5].FApplicationID
								this.toRouter(this.items[0].children[5].FApplicationName)
							} else if (sessionStorage.getItem('itemPath') == 'SendCarDetail'  &&  sessionStorage.ApplicationName == '已过期') { // 派车单过期
								this.selectId = this.items[1].children[5].FApplicationID
								this.toRouter(this.items[1].children[5].FApplicationName)
							} else {                                                         // 其他
								if(sessionStorage.ApplicationName){
									this.items.map(item=>{
										item.children.map(cell=>{
											if(cell.FApplicationText == sessionStorage.ApplicationName){
												this.selectId = cell.FApplicationID
												this.toRouter(cell.FApplicationName)
											}
										})
									})
								}
							}
							this.getOrderDeliveryCount()
						}
					},
					error =>{

					}
				);
			},
			getOrderDeliveryCount(){
				this.ApiRequestPostNOMess('api/mall/ebsale/order/get-list-order-delivery-count',{
				}).then(
					res=>{
						this.num1 = res.obj.fOrderUnpaidCount
						this.num2 = res.obj.fOrderStaleCount
						this.num3 = res.obj.fDeliveryUnpaidCount
			
					},
					error =>{
			
					}
				);
			},
			
		}
	}
</script>

<style lang="scss">
	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.pt20 {
		min-height: 720px;
		margin-top: 20px;
		width: 170px;
		margin-right: 15px;

		.menu-width {
			background-color: rgba(248, 249, 254, 1);
			width: 100%;
			border-right: 0;
			min-height: 650px;
			padding-bottom: 5px;

			.el-menu {
				background-color: rgba(248, 249, 254, 1);

				.menuchild {
					background-color: #fff;
					margin: -5px 5px 0px 5px;

					li {
						cursor: pointer;
						list-style: none;
						margin-left: 26px;
						line-height: 34px;
						height: 34px;
						color: #666666;
						font-size: 14px;
					}

					li:before {
						content: "";
						display: inline-block;
						width: 8px;
						height: 8px;
						background-color: #cccccc;
						border-radius: 50%;
						margin-right: 10px;
					}

					li:after {
						content: "|";
						color: rgb(120, 181, 254);
						background-color: rgb(120, 181, 254);
						position: absolute;
						left: 0;
					}


				}

			}


			.el-submenu .el-submenu__title {
				line-height: 40px;
				height: 40px;
				margin: 5px;
				background-color: #fff;
			}

			.el-menu-item {
				line-height: 40px;
				height: 40px;
				margin: 5px;
				background-color: #fff;
				// padding-left: 0 !important;
			}

			.el-icon-arrow-down:before {
				content: "\e6df" !important;
				font-size: 16px;
				font-weight: 600;
			}
		}

		.border:last-child {
			border-bottom: 1px solid #f2f2f2;
		}

		.ma-botn {
			margin-top: 10px;
			margin-right: 13px;
			background-color: #fff;
		}

		.border {

			border-left: 1px solid #f2f2f2;
			border-right: 1px solid #f2f2f2;
			border-top: 1px solid #f2f2f2;
			width: 142px;
			height: auto;
		}

		.titlefont {
			width: 65px;
			height: 19px;
			font-size: 16px;

			font-weight: bold;
		}

		.padding-13 {
			padding: 13px;
		}

		.pading-l-30 {
			font-size: 14px;
			padding-left: 17px;
			padding-top: 10px;
			cursor: pointer;
		}
	}
	.myitem {
		.el-badge__content.is-fixed {
			right: -6px;
			top: 10px;
		}
		.el-badge__content {
			background-color: #FF0000;
		}
	}
</style>
