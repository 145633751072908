import Vue from 'vue'

export function getTime(time) {
	// 修改者：张斌睿
	// 时间：2020-11-4
	let date = new Date(time);
	let year = date.getFullYear(); //年
	let month = date.getMonth() + 1; //月
	let day = date.getDate(); //日
	let hour = date.getHours(); //时
	let minute = date.getMinutes(); //分
	let second = date.getSeconds(); //秒
	month = month > 9 ? month : '0' + month;
	day = day > 9 ? day : '0' + day;
	hour = hour > 9 ? hour : '0' + hour;
	minute = minute > 9 ? minute : '0' + minute;
	second = second > 9 ? second : '0' + second;

	return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
}

export function getDate(time) {
  // 修改者：张斌睿
  // 时间：2020-11-4
	let date = '';
  if (time == null || time == '') {
    date = new Date();
  }else{
		date = new Date(time);
	}
  // let date = new Date(time);
  let year = date.getFullYear(); //年
  let month = date.getMonth() + 1; //月
  let day = date.getDate(); //日
  month = month > 9 ? month : '0' + month;
  day = day > 9 ? day : '0' + day;

  return `${year}-${month}-${day}`;
}

export function getMD(time) {
  // 修改者：张斌睿
  // 时间：2020-11-4
	let date = '';
  if (time == null || time == '') {
    date = new Date();
  }else{
		date = new Date(time);
	}
  // let date = new Date(time);
  let year = date.getFullYear(); //年
  let month = date.getMonth() + 1; //月
  let day = date.getDate(); //日
  month = month > 9 ? month : '0' + month;
  day = day > 9 ? day : '0' + day;

  return `${month}-${day}`;
}

export function getYM(time) {
	let date = '';
	if (time == null || time == '') {
		date = new Date();
	} else {
		date = new Date(time);
	}
	let year = date.getFullYear(); //年
	let month = date.getMonth() + 1; //月
	let day = date.getDate(); //日
	month = month > 9 ? month : '0' + month;
	day = day > 9 ? day : '0' + day;

	return `${year}-${month}`;
}

export function toTree(data) {

	data.forEach(function(item) {
		item.children = [];
	});

	var map = {};
	data.forEach(function(item) {
		map[item.id] = item;
	});

	var val = [];
	data.forEach(function(item) {
		// 以当前遍历项，的pid,去map对象中找到索引的id
		var parent = map[item.parentId];
		// 如果找到索引，那么说明此项不在顶级当中,那么需要把此项添加到，他对应的父级中
		if (parent) {
			(parent.children || (parent.children = [])).push(item);
		} else {
			//如果没有在map中找到对应的索引ID,那么直接把 当前的item添加到 val结果集中，作为顶级
			val.push(item);
		}
	});
	return val;

}


export function toMenTree(data) {
	data.forEach(function(item) {
		item.children = [];
	});
	var map = {};
	data.forEach(function(item) {
		map[item.FApplicationID] = item;
	});
	var val = [];
	data.forEach(function(item) {
		var parent = map[item.FParentID];
		if (parent) {
			(parent.children || (parent.children = [])).push(item);
		} else {
			val.push(item);
		}
	});
	return val;
}





//根据场景类型选择消息类型提示
export function tipsSuccess(message) {
	return this.$message({
		showClose: true,
		message: message,
		duration: 3000,
		type: 'success'
	});
}

export function tipsError(message) {
	return this.$message({
		showClose: true,
		message: message,
		duration: 3000,
		type: 'error'
	});
}

export function tipsInfo(message) {
	return this.$message({
		showClose: true,
		message: message,
		duration: 3000,
		type: 'info'
	});
}


// 重组列表  返回以每 num 个为一组的数据
// 张斌睿
export function makeListForNum(list, num) {
	let returnLIst = [];
	for (var i = 0; i < list.length; i += num) {
		returnLIst.push(list.slice(i, i + num));
	}
	return returnLIst;
}


//转换数字金额为大写金额
export function changeNumMoneyToChinese(money) {
	var cnNums = new Array("零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖"); //汉字的数字
	var cnIntRadice = new Array("", "拾", "佰", "仟"); //基本单位
	var cnIntUnits = new Array("", "万", "亿", "兆"); //对应整数部分扩展单位
	var cnDecUnits = new Array("角", "分", "毫", "厘"); //对应小数部分单位
	var cnInteger = "整"; //整数金额时后面跟的字符
	var cnIntLast = "元"; //整型完以后的单位
	var maxNum = 999999999999999.9999; //最大处理的数字
	var IntegerNum; //金额整数部分
	var DecimalNum; //金额小数部分
	var ChineseStr = ""; //输出的中文金额字符串
	var parts; //分离金额后用的数组，预定义    
	var Symbol = ""; //正负值标记
	if (money == "") {
		return "";
	}

	money = parseFloat(money);
	if (money >= maxNum) {
		alert('超出最大处理数字');
		return "";
	}
	if (money == 0) {
		ChineseStr = cnNums[0] + cnIntLast + cnInteger;
		return ChineseStr;
	}
	if (money < 0) {
		money = -money;
		Symbol = "负 ";
	}
	money = money.toString(); //转换为字符串
	if (money.indexOf(".") == -1) {
		IntegerNum = money;
		DecimalNum = '';
	} else {
		parts = money.split(".");
		IntegerNum = parts[0];
		DecimalNum = parts[1].substr(0, 4);
	}
	if (parseInt(IntegerNum, 10) > 0) { //获取整型部分转换
		var zeroCount = 0;
		var IntLen = IntegerNum.length;
		for (var i = 0; i < IntLen; i++) {
			var n = IntegerNum.substr(i, 1);
			var p = IntLen - i - 1;
			var q = p / 4;
			var m = p % 4;
			if (n == "0") {
				zeroCount++;
			} else {
				if (zeroCount > 0) {
					ChineseStr += cnNums[0];
				}
				zeroCount = 0; //归零
				ChineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
			}
			if (m == 0 && zeroCount < 4) {
				ChineseStr += cnIntUnits[q];
			}
		}
		ChineseStr += cnIntLast;
		//整型部分处理完毕
	}
	if (DecimalNum != '') { //小数部分
		var decLen = DecimalNum.length;
		for (var i = 0; i < decLen; i++) {
			var n = DecimalNum.substr(i, 1);
			if (n != '0') {
				ChineseStr += cnNums[Number(n)] + cnDecUnits[i];
			}
		}
	}
	if (ChineseStr == '') {
		ChineseStr += cnNums[0] + cnIntLast + cnInteger;
	} else if (DecimalNum == '') {
		ChineseStr += cnInteger;
	}
	ChineseStr = Symbol + ChineseStr;

	return ChineseStr;
}




Vue.prototype.toTree = toTree;
Vue.prototype.toMenTree  = toMenTree ;
Vue.prototype.tipsSuccess = tipsSuccess;
Vue.prototype.tipsError = tipsError;
Vue.prototype.tipsInfo = tipsInfo;
Vue.prototype.makeListForNum = makeListForNum;
Vue.prototype.changeNumMoneyToChinese = changeNumMoneyToChinese;
