<template>
	<div :class="themeClass" class="headerC">
		<div class="head ">

			<div class="head-1">
				<router-link to="/">
					<img :src="logoUrl" style="height: 58px;width:182px;background-size:cover;" />
				</router-link>

			</div>
      <!-- 区域切换 -->
      <div class="civ">
        <div class="areastyle" @click="showlist">
          <span style="vertical-align: middle; padding-right: 5px; display: inline-block">【</span>
          <img src="../../assets/dingwei.png" alt="" style="width: 20px; heihgt:20px;margin-right: 5px;vertical-align: middle;">
          <span class="text" style="vertical-align: middle; max-width: 145px;min-width: 95px; display: inline-block;">{{area}}</span>
          <span style="vertical-align: middle; padding-left: 7px; display: inline-block">】</span>
        </div>
        <div v-show="showAreaList" class="AreaList">
          <div class="top">
            <div class="item" :class="active == 1 ? 'active': ''" @click="cutarea(1, province)">{{province.label}}</div>
            <div v-show="city.label" class="item" :class="active == 2 ? 'active': ''" @click="cutarea(2, city)">{{city.label}}</div>
            <div v-show="district.label" class="item" :class="active == 3 ? 'active': ''" @click="cutarea(3, district)">{{district.label}}</div>
            <i class="el-icon-close close" @click="closeList"></i>
          </div>
          <div class="body">
            <div class="item" v-for="(item, index) of areaList" :key="item.value" @click="getArea(item)">
                {{item.label}}
            </div>
          </div>
        </div>
      </div>
			<div class="so marato search-box">
				<el-input placeholder="请输入搜索内容" style="font-size: 15px;text-align: left;line-height: 13px;" v-model="search"
				 @keyup.native.enter="searchClick">
					<el-button slot="append" @click="searchClick" style="color: #ffffff;font-size: 15px;padding: 12px 38px;">搜索</el-button>
				</el-input>
			</div>
			<div class="so mar20">
				<div class="font-size12 font-color-333">客服热线（周一至周五9:00-22:00）</div>
				<div class="flex-row-space-between margin-t-5">
					<div class="LandlineImage">
						<imageBox :src="require('@/assets/Landline.gif')"></imageBox>
					</div>
					<div class="font-size22 margin-r-5 font-weight700 color-theme  ">
						400-666-3439
					</div>
				</div>
			</div>
		</div>
		<div  :class="isFixed  ?'menu-boxS' :'menu-box'"  ref="topElement">
			<el-menu :default-active="activeMenu" class="el-menu-demo" mode="horizontal" active-text-color="#409eff" router>
				<el-menu-item style="width:170px;" index="/home">首页</el-menu-item>
				<el-menu-item index="/Goods">商品</el-menu-item>
				<!-- <el-menu-item index="/ShopList">店铺</el-menu-item> -->
				<el-menu-item index="/SeckillBilList">限时秒杀</el-menu-item>
				<el-menu-item index="/businessme/AllOrder" @click="clickAllOrder">我的业务</el-menu-item>
				<el-menu-item index="/businessBuy">企业购</el-menu-item>
				<el-menu-item index="/pointPurchase">积分购</el-menu-item>
				<el-menu-item index="/goldMall">金币商城</el-menu-item>
				<el-menu-item index="/PersonalContent">个人中心</el-menu-item>
        <el-menu-item index="/cement">特水展页</el-menu-item>
				<el-menu-item index="/SalesNetwork">销售网络</el-menu-item>
				<!-- <el-submenu :index="i.id" v-for="(i,index) of items" :key="index" popper-class="menuclass">
				<template slot="title">{{i.title}}</template>
				<el-menu-item popper-class="menuclass2">

					<div class="menuclass-itemn" :index="ids.id" v-for="(ids,indexs) of i.child" :key="indexs">

						<div class="menuclass-itemn2">
							{{ids.childNmae}}
						</div>
						<div class="menuclass-itemn3" :index="ids2.id" v-for="(ids2,indexSs) of ids.child" :key="indexSs">
							{{ids2.title}}
						</div>

					</div>
				</el-menu-item>
			</el-submenu> -->
			</el-menu>
		</div>


	</div>
</template>

<script>
  import SERVER from '../../../config/config.json'
	import imageBox from '@/components/imageBox/imageBox.vue'
	import searchAll from '@/components/searchAll.vue'
	import {
		mapMutations,
		mapGetters,
		watch
	} from 'vuex'
	import parm from '@/components/loyout/publicList'
	export default {
		name: 'Header',
		components: {
			imageBox,
			searchAll
		},
		props: {
		  // scrollPosition:{
		  //   type:Number,
			// default: 0
		  // },
		  headerTitle:{
			  type:Boolean,
			  default:false,
		  }
		},
		data() {
			return {
        scrollPosition: 0 ,// 默认滚动位置为0
				logoUrl: '',
				seltType: '1',
				activeIndex: "",
				items: parm.pro,
				search: "", // 搜索条件
				test: parm.parmas,
				arr: parm.arr,
				shouPath:"",
				isFixed:false,
        showAreaList: false,
        areaList: [],
        area: '',
        province: {}, // 省
        city: {}, // 市
        district: {}, // 区
        oldprovince: {}, // 省
        oldcity: {}, // 市
        olddistrict: {}, // 区
        ipAdcode: '',
        active: 1,
        isRefreshing: false,
        pageroute: 0,
			}
		},
		watch: {
			'$store.getters.getSearchValue': {
				handler(val){
					if(val == ""){
						this.search = val
					}
					
					// console.log(val,'ceshivuex')
				}
			},
			'$store.getters.getshouPathValue': {
				handler(val){
					console.log(val)
					if(val == ""){
						this.shouPath = val
					}
					
					// console.log(val,'ceshivuex')
				}
			},
      $route:{
				immediate:true,
				handler(to){
          // this.pageroute = to.path
				}
			},
		},
		computed: {
			...mapGetters(['getThemeName', "getLogoUrl",'getSearchValue','getshouPathValue', 'getareaCut']),

			themeClass() {
				return `theme-${this.getThemeName}`;
			},
			activeMenu() {
				if (this.$router.currentRoute.path.indexOf('businessme') != -1) {
					return '/businessme/AllOrder';
				} else {
					return this.$router.currentRoute.path;
				}
			}
		},
    created() {
      if (sessionStorage.getItem('area')) {
        this.area = JSON.parse(sessionStorage.getItem('area'))
        this.oldprovince = this.province = JSON.parse(sessionStorage.getItem('province'))
        this.oldcity = this.city = JSON.parse(sessionStorage.getItem('city'))
		if (sessionStorage.getItem('district')) {
			this.olddistrict = this.district =  JSON.parse(sessionStorage.getItem('district'))
		}
      } else {
		this.getIPAdcode()

      }
    },
 	mounted() {
		// window.addEventListener('beforeunload', this.beforeUnload); 
		setTimeout(() => {
			window.addEventListener('scroll', this.handleScroll)
		}, 1000);
			
		this.search=this.$store.getters.getSearchValue
		this.shouPath= this.$store.getters.getshouPathValue
		this.logoUrl = this.$store.getters.getLogoUrl ? this.$store.getters.getLogoUrl : this.weblogUrl;
	},
	methods: {
		clickAllOrder() {
			sessionStorage.ApplicationName = '全部订单'
		},
		cutarea(val,item) {
			this.active = val
			if (val == 1) {
			  this.getdata(-1)
			} else {
			  this.getdata(item.fParentID)
			}
		},
		handleScroll() {
      // console.log(this.$refs.topElement)
			this.scrollPosition = window.scrollY;
			       
			if (this.$refs.topElement != null && this.scrollPosition > this.$refs.topElement.offsetTop) {
			    this.isFixed = true;
			} else {
			    this.isFixed = false;
			}
		},  
    getIPAdcode() {
        fetch('https://restapi.amap.com/v3/ip?key=' + SERVER.gaodeMapKey,{
			method:'get'
		}).then( async response=>{
            let res  = await  response.json()
			console.log(1123213,res)
            this.acquireFgId(res.adcode)
            this.oldprovince = this.province = {
                label: res.province,
                fParentID: -1
            }
            this.ipAdcode = res.adcode
			sessionStorage.setItem('province', JSON.stringify(this.province))
          }).catch(data => {  
			console.log(data); // 这里是API返回的数据，你可以按需处理它  
			this.acquireFgId('650100')
			this.oldprovince = this.province = {
			    label: '新疆维吾尔自治区',
			    fParentID: -1
			}
		})  
    },
      //获取行政区域地
	acquireFgId(val){
		//通过接口获取区域编码对应的区域id
		this.ApiRequestPost('api/mall/ebbase/governmen-area/get-by-code', {
			fGovernmenAreaCode: val
		}, ).then(res => {
			if(res.obj){
				this.getPropertieData(res.obj.fGovernmenAreaCode)
				this.oldcity = this.city = {
				    label: res.obj.fGovernmenArea,
				    value: res.obj.fGovernmenAreaID,
				    fParentID: res.obj.fParentID,
				    code: res.obj.fGovernmenAreaCode
				}
				sessionStorage.setItem('city', JSON.stringify(this.city))
				if (sessionStorage.getItem('district')) {
					this.province = JSON.parse(sessionStorage.getItem('province'))
					this.city = JSON.parse(sessionStorage.getItem('city'))
					this.district =  JSON.parse(sessionStorage.getItem('district'))
					this.area = JSON.parse(sessionStorage.getItem('area'))
				} else {
				  this.area = this.province.label + ' ' + this.city.label
				  sessionStorage.setItem('area', JSON.stringify(this.area))
				}
			}
		}, error => {
		});
	},
    showlist() {
		this.active = 1
		this.province = this.oldprovince, // 省
		this.city = this.oldcity, // 市
		this.district = this.olddistrict // 区
		this.getdata(-1)
		this.showAreaList = true
    },
    closeList() {
		this.showAreaList = false
    },
    getdata(val) {
        this.ApiRequestPost('api/mall/ebbase/governmen-area/get-list-by-parentid-onstatus', {fParentID: val }, false)
        .then(res => {
			const nodes = Array.from(res.obj.items).map(item=>({
				value:item.fGovernmenAreaID,
				label:item.fGovernmenArea,
				fParentID: item.fParentID,
				fFullArea: item.fFullArea,
				code: item.fGovernmenAreaCode,
				fGrade: item.fGrade // 等级
			}))
			this.areaList = nodes
        })
    },
	getArea(item) {
		if (item.fGrade == 3) {
			this.district = item
			this.area = this.province.label + ' ' + this.city.label + ' ' + this.district.label
			this.oldprovince = this.province, // 省
			this.oldcity = this.city, // 市
			this.olddistrict = this.district, // 区
			this.showAreaList = false
			sessionStorage.setItem('province', JSON.stringify(this.province))
			sessionStorage.setItem('city', JSON.stringify(this.city))
			sessionStorage.setItem('district', JSON.stringify(this.district))
			sessionStorage.setItem('area', JSON.stringify(this.area))
			
			this.getPropertieData(this.district.code)
        } else if (item.fGrade == 2) {
			this.active = 2
			this.city = item
			this.district = {}
			this.getdata(item.value)
        } else if (item.fGrade == 1) {
            this.active = 1
            this.province = item
            this.city = {}
            this.district = {}
            this.getdata(item.value)
        }
      },
	getPropertieData(code) {
		this.ApiRequestPost('api/mall/goods/goods-class-properties-data/get-class-propertie-data-area', {fGovCode: code }, false)
		  .then(res => {
			if (res.obj) {
				sessionStorage.setItem('ClassPropertie',JSON.stringify(res.obj))
			} else {
				sessionStorage.removeItem('ClassPropertie')
			}
			this.getGodPro()
		})
	} ,
	//获取商品属性值
	getGodPro() {
		let fGovCode = ''
		if (sessionStorage.getItem('district')) {
			let district =  JSON.parse(sessionStorage.getItem('district'))
			fGovCode = district.code
		} else {
			fGovCode = this.ipAdcode
		}
		this.ApiRequestPost('/api/mall/goods/goods-class/get-area-navigations', {
			fGovCode: fGovCode
		}).then(res=>{
			this.classifyListGoods= res.obj.goodsClasses;
			this.$store.dispatch("changeclassifyListData", this.classifyListGoods); 
			this.pageroute = this.$store.getters.getareaCut + 1
			this.$store.commit("changeAreaCut", this.pageroute);
			// this.$forceUpdate()
		})
			
	},		
      // 点击搜索按钮
			searchClick() {
				this.shouPath=this.$route.path
				console.log(this.$route.path)
					this.$router.push({
						path: "/Goods",
					});
          this.search = this.search.trim()
          this.search = this.search.replace(/\s+/g, '')
          console.error(this.search)
					this.$store.commit("changeSearchData", this.search); 
					this.$store.commit('changeshouPathValue',this.shouPath)
				let arrd = this.uni(this.arr);
				
			},
			uni(objarray) {


				let len = objarray.length;
				let tempJson = {};

				let res = [];

				for (let i = 0; i < len; i++) {
					//取出每一个对象
					tempJson[JSON.stringify(objarray[i])] = true;

				}


				let keyItems = Object.keys(tempJson);

				for (let j = 0; j < keyItems.length; j++) {

					res.push(JSON.parse(keyItems[j]));
				}

				return res;



			}

		}


	}
</script>
<style lang="scss">
	.el-menu-item.is-active {
		border-bottom: 0px rgba(0, 0, 0, 0) solid !important;
	}

	.menuclass {
		position: absolute !important;
		left: 160px !important;
		background-color: #f8f9fe;
		left: calc(50% - 550px) !important;
		top: 160px !important;
		min-width: 1100px;
		max-width: 1100px;

		.el-menu-item {}

		.menuclass-itemn {
			margin-top: 10px;
			width: 100%;
			overflow: hidden;
			background-color: #f8f9fe;
		}

		.menuclass-itemn2 {
			margin-bottom: 5px;
			text-align: center;
			height: 30px;
			width: 6%;
			font-weight: 700;
			font-size: 13px;
			color: #fff;
			background-color: #333;
		}

		.menuclass-itemn3 {
			width: 10%;
			line-height: 2;
			float: left;
			font-size: 12px;
		}
	}

	.menuclass ul {
		height: 100%;
		background-color: #f8f9fe !important;
		margin-top: 0;
		text-align: center;
	}

	.menuclass ul li {
		height: 100% !important;
		background-color: #f8f9fe !important;
	}

	.el-menu--horizontal>.el-submenu .el-submenu__title {
		height: 40px !important;
		background-color: #f8f9fe !important;
		line-height: 40px;
		color: #333;
		;
	}

	.el-menu--horizontal>.el-submenu .el-submenu__title:hover {
		background-color: rgba(1, 115, 254, 1) !important;
		color: #fff !important;
	}

	.el-menu .el-menu--horizonta {}
</style>
<style lang="scss" scoped="scoped">
	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.bg-color-theme {
		@include themify($themes) {
			background-color: themed('themes_color');
		}
	}

	/deep/.el-input__inner {
		@include themify($themes) {
			border: 2px solid themed('themes_color') !important;
			height: 42px !important;
			line-height: 42px !important;
		}

		border-radius: 0;
		/* width: 423px; */
    width: 287px;
	}

	/deep/.el-input-group__append {
		@include themify($themes) {
			border: 1px solid themed('themes_color');
			background-color: themed('themes_color');
		}

		border-radius: 0;
	}

	.search-button {
		user-select: none;
	}

	.headerC {
		// max-width: 1100px;
		// min-width: 1100px;

		margin: 0 auto;

		select {
			margin-left: 20px;
			border: 1px solid rgba(153, 153, 153, 1);
			height: 25px;
		}

		.el-menu-demo {
			max-width: 1100px;
			min-width: 1100px;
			margin: 0 auto;
			text-align: center;
			background: #333;
			border-bottom: 0;
			z-index:10000;
		}

		.mar20 {
			margin: 20px;
		}

		.marato {
			margin: 24px auto;
		}

		.head-1 {
			// height: 20px;
			// margin-top: 20px;
			margin: auto 0;
		}

		.head-1 a {
			font-size: 18px;
			font-weight: 700;
		}

		.el-header {
			margin-top: 30px;
			padding: 0;
			box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.149019607843137);
			height: 130px !important;

		}

		.head {
			display: flex;
			height: 89px;
			max-width: 1100px;
			margin: 0 auto;
		}


		.el-menu--horizontal>.el-menu-item:not(.is-disabled):focus,
		.el-menu--horizontal>.el-menu-item:not(.is-disabled):hover,
		.el-menu--horizontal>.el-submenu .el-submenu__title:hover {
			background-color: rgba(1, 115, 254, 1) !important;
			color: #fff !important;
			height: 40px !important;
		}

		.el-menu--horizontal>.el-menu-item.is-active {
			background-color: rgba(1, 115, 254, 1);
			color: #fff !important;
			// border-bottom: 2px solid #333 !important;
		}

		.el-menu--horizontal>.el-menu-item {
			width: 100px;
			line-height: 41px;
			background-color: #333;
			height: 40px !important;
			color: #fff !important;
		}

		.el-submenu__title {
			height: 40px !important;
		}

		.el-submenu {
			height: 40px !important;
		}

		.el-header .el-menu {
			max-width: 1100px;
			min-width: 1100px;
			margin: 0 auto;
		}

		.el-header .logo {
			height: 60px;
			width: 189px;
			float: left;
			margin-right: 100px;
		}

		.el-header .so {
			float: right;
			
		}
		.so .el-input-group input {
			height: 32px;
			border: 1px solid #366CFC;
			border-radius: 0;

		}

		.el-input-group__append {
			background-color: #366CFC !important;
			border: 1px solid #366CFC !important;
			color: #fff !important;
		}
	}

	.menu-box {
		background-color: #333;
	}
    .menu-boxS {
		width:100%;
    	background-color: #333;
		position: fixed;
		top:30px;
		left:0px;
		z-index:10000;
    }
	
	.menu-box::v-deep {
		.el-icon-arrow-down:before {
			content: ''
		}
	}

	.LandlineImage {
		width: 25px;
		height: 25px;
	}
	.font-size15{
		font-size: 15px;
	}
  .civ {
    position: relative;
    margin: auto 0 auto 25px;
    height: 40px;    
    line-height: 40px;
    font-size: 15px;
    .areastyle {
      display: inline-block;
      margin: auto 0 auto 0;
      /* height: 40px;    
      line-height: 40px; */
      /* border: 1px solid rgba(204, 204, 204, 1); */
      padding: 0 5px;
      font-size: 15px;
      .text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      
    }
    .AreaList {
      width: 460px;
      /* height: 200px; */
      border: 1px solid rgba(204, 204, 204, 1);
      background-color: #fff;
      padding: 10px;
      position: absolute;
      top: 45px;
      left: 0;
      z-index: 99999;
      .top {
        border-bottom: 2px solid #366CFC;
        line-height: 29px;
        position: relative;
        .close {
          position: absolute;
          right: 0;
          top: 0;
        }
        .item {
          display: inline-block;
          height: 27px;
          line-height: 27px;
          border: 1px solid rgba(204, 204, 204, 1);
          border-bottom: 0px solid #fff;
          padding: 0 15px;
          border-radius: 5px 5px 0 0;
          margin-right: 20px;
          font-size: 13px;
        }
        .active {
          background-color: #366CFC;
          color: #fff;
          border: 1px solid #366CFC;
        }
      }
      .body {
        /* line-height: 30px;
        height: 30px; */
        .item {
          display: inline-block;
          padding-right: 10px;
          font-size: 13px;
        }
      }
    } 
  }
  
</style>
